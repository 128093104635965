main {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin: 30px;

  input {
    box-sizing: border-box;
    margin: 0 0 0 auto;
    padding: 0 15px;
    width: 200px;
    height: 30px;
    background-color: #f1f1f1;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s;
      &:focus {
        border: 1px solid #333333;
        outline: none;
      }
  }
}


.formOpen-enter {
  opacity: 0;
  transform: scale(0.9);
}

.formOpen-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.formOpen-exit {
  opacity: 1;
}

.formOpen-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}