header {
  padding: 10px 30px;
  color: #FFFFFF;
  background-color: #00acc1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46);

  button {
    display: block;
    box-sizing: border-box;
    margin-left: auto;
    padding: 10px 20px;
    background-color: inherit;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border: none;
    border-radius: 7px;
    transition: 0.3s;
      &:hover { 
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1); 
      }
      &:active { 
        transform: scale(0.8);
        outline: none; 
      }
      &:focus { outline: none; }
  }
}