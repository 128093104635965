@import '~normalize-scss/sass/normalize/import-now';
@import './font.scss';


html, body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 18px;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

