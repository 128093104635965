.notes {
  margin-left: 70px;
  padding: 10px;
  font-size: 20px;
  color: #003f47;

  span {
    font-size: 30px;
    font-weight: bold;
    color: #da6b38;
  }

  a {
    display: block;
    margin: 10px;
    padding: 5px;
    text-align: center;
    color: #003f47;
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 7px 12px -5px rgba(112, 166, 173, 0.4);
    transition: 0.5s;
    &:hover { 
      letter-spacing: 2px;
    }
    &:active { 
      transform: scale(0.8);
      outline: none; 
    }
  }
}