.addNews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  width: 350px;
  height: 400px;
  background-color: #f1f1f1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.1), 0 7px 12px -5px rgba(112, 166, 173, 0.4);
  transition: 0.3s;
    &:hover {
      cursor: pointer;
      box-shadow: 0 14px 28px rgba(0,0,0,0.2), 0 10px 10px rgba(112, 166, 173, 0.2);
    }
    &:active { box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.1), 0 7px 12px -5px rgba(112, 166, 173, 0.4); }

  svg {
    width: 30%;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    color: #003f47;
  }
}

.addNews-hide {
  visibility: hidden;
  margin: 0 10px;
  width: 350px;
  height: 400px;
}