.form-wrap {
  width: 500px;
  margin: 10vh auto 0 auto;
  border-radius: 20px;
  box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);

  div {
    padding: 50px 0 40px 0;
    background-color: #57b846;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 20px 20px 0 0;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    ::placeholder {
      color: #1b3815;
    }

    input {
      box-sizing: border-box;
      margin: 10px 0;
      padding: 0 35px;
      width: 390px;
      height: 50px;
      background-color: #ebebeb;
      border-radius: 25px;
      border: none;
      font-size: 15px;
      font-weight: 700;
      transition: 0.3s;
        &:focus {
          width: 410px;
          padding: 0 45px;
          outline: none;
        }
    }

    button {
      display: block;
      box-sizing: border-box;
      width: 390px;
      margin-top: 40px;
      padding: 15px 0;
      background-color: #57b846;
      color: #ffffff;
      font-size: 17px;
      font-weight: 700;
      text-align: center;
      border: none;
      border-radius: 25px;
      transition: 0.5s;
        &:hover { 
          cursor: pointer;
          background-color: #333333; 
        }
        &:active { 
          transform: scale(0.8);
          outline: none; 
        }
        &:focus { outline: none; }
    }

    span {
      min-height: 20px;
      margin-top: 80px;
      font-size: 15px;
      font-weight: 400;
      color: #999999;
    }
  }
}