@font-face {
  font-family: 'Roboto';
  font-style: light;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: regural;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: medium;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/font/Roboto/roboto-v19-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
}