.addForm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 60vw;
  height: 60vh;
  margin: 0 auto;
  padding: 20px;
  background-color: #f1f1f1;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.1), 0 7px 12px -5px rgba(112, 166, 173, 0.4);
  z-index: 1;

  svg {
    width: 25px;
    margin-left: 58vw;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: 0.3s;
      &:hover { cursor: pointer; filter: none; }
      &:active { transform: scale(0.8); }
  }

  section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      padding: 50px 0;

      ::placeholder {
        color: #3f3f3f;
      }

      label {
        box-sizing: border-box;
        margin: 10px 0;
        padding: 7px 15px;
        width: 40%;
        background-color: #00d4f021;
        border-radius: 5px;
        border: 1px solid transparent;
        color: #3f3f3f;
        font-size: 15px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.24);
        transition: 0.3s;
          &:hover { 
            cursor: pointer; 
            border: 1px solid #333333;
          }
          &:active {
            transform: scale(0.9);
          }
          &:focus {
            outline: none;
          }

        input {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
      }
  
      input {
        box-sizing: border-box;
        margin: 10px 0;
        padding: 0 15px;
        width: 60%;
        height: 30px;
        background-color: #00d4f021;
        border-radius: 5px;
        border: 1px solid transparent;
        font-size: 15px;
        font-weight: 500;
        transition: 0.3s;
          &:focus {
            border: 1px solid #333333;
            outline: none;
          }
      }

      textarea {
        resize: none;
        box-sizing: border-box;
        margin: 10px 0;
        padding: 10px 15px;
        width: 60%;
        height: 180px;
        background-color: #00d4f021;
        border-radius: 5px;
        border: 1px solid transparent;
        font-size: 15px;
        font-weight: 500;
        transition: 0.3s;
          &:focus {
            border: 1px solid #333333;
            outline: none;
          }
      }
      
      span {
        height: 20px;
        text-align: center;
        margin: 7px 0;
      }

      button {
        width: 60%;
        padding: 7px 0 4px 0;
        background-color: transparent;
        color: #003f47;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 1px;
        text-align: center;
        border: 1px solid #00acc1;
        border-radius: 7px;
        transition: 0.5s;
        &:hover { 
          cursor: pointer;
          letter-spacing: 5px;
        }
        &:active { 
          transform: scale(0.8);
          outline: none; 
        }
        &:focus { outline: none; }
      }
    }

    div {
      position: relative;
      width: 350px;
      height: 400px;
      margin: 10px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: 0.5s;
        &:hover {
          cursor: pointer;
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  
          h5 {
            top: 0;
            padding: 20px 0 0 70px;
            background-color: rgba(8, 41, 43, 0.8);
          }
        }
        &:active {
          transform: scale(1.1);
        }
    }
  
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      background-color: #f1f1f1;
      transition: 0.5s;
    }
  
    h5 {
      position: absolute;
      top: 170px;
      box-sizing: border-box;
      margin: 0;
      padding: 20px 0 0 20px;
      width: 100%;
      height: 60px;
      font-size: 1.1rem;
      font-weight: 700;
      color: white;
      background-color: rgba(8, 41, 43, 0.6);
      transition: 0.5s;
    }
  
    p {
      box-sizing: border-box;
      max-height: 160px;
      margin: 0;
      padding: 7px;
      font-size: 1rem;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
    }
  }
  
  .openNews {
    img {
      height: 0;
      transition: 0.5s;
    }
  
    h5 {
      top: 0;
      height: 50px;
    }
  
    p {
      margin: 50px 5px 5px 5px;
      max-height: 330px;
      font-size: 1.1rem;
      font-weight: 400;
      overflow-y: auto;
      transition: 0.5s;
    }
  }

}