.allNews {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 30px;
  min-height: 420px;
    @media screen and (max-width: 1400px) {
      padding: 0 10vw;
    }
    @media screen and (max-width: 1200px) {
      padding: 0 5vw;
    }
    @media screen and (max-width: 1000px) {
      padding: 0 3vw;
    }
    @media screen and (max-width: 750px) {
      justify-content: center;
    }

  div {
    position: relative;
    max-width: 400px;
    max-height: 450px;
    min-width: 300px;
    min-height: 420px;
    margin: 30px 10px;
    width: calc((100% / 4) - 20px);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: 0.5s;
      &:hover {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

        h5 {
          top: 0;
          padding: 20px 0 0 70px;
          background-color: rgba(8, 41, 43, 0.8);
            @media screen and (max-width: 450px) {
              padding: 17px 0 0 70px;
            }
        }
      }
      @media screen and (max-width: 1400px) {
        width: calc((100% / 2) - 20px);
      }
      @media screen and (max-width: 800px) {
        min-height: 400px;
      }
      @media screen and (max-width: 750px) {
        width: 400px;
      }
      @media screen and (max-width: 450px) {
        min-height: 300px;
      }
      @media screen and (max-width: 450px) {
        min-width: 100%;
      }

    button {
      position: absolute;
      top: -32px;
      right: 0;
      width: 150px;
      padding: 5px 0 2px 0;
      background-color: transparent;
      color: #da6b38;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: 1px;
      text-align: center;
      border: 1px solid #da6b38;
      border-radius: 3px;
      transition: 0.5s;
        &:hover { 
          cursor: pointer;
          letter-spacing: 5px;
        }
        &:active { 
          transform: scale(0.8);
          outline: none; 
        }
        &:focus { outline: none; }
    }
      
  }

  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    background-color: #f1f1f1;
    transition: 0.5s;
      @media screen and (max-width: 450px) {
        height: 150px;
      }
  }

  h5 {
    position: absolute;
    top: 170px;
    box-sizing: border-box;
    margin: 0;
    padding: 20px 0 0 20px;
    width: 100%;
    height: 60px;
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
    background-color: rgba(8, 41, 43, 0.6);
    transition: 0.5s;
      @media screen and (max-width: 450px) {
        top: 100px;
        height: 50px;
        padding: 17px 0 0 20px;
      }
  }

  p {
    box-sizing: border-box;
    max-height: 180px;
    margin: 0;
    padding: 7px;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;
  }
}

.openNews {
  img {
    height: 0;
    transition: 0.5s;
  }

  h5 {
    top: 0;
    height: 50px;
  }

  p {
    margin: 50px 5px 5px 5px;
    max-height: 330px;
    font-size: 1.1rem;
    font-weight: 400;
    overflow-y: auto;
    transition: 0.5s;
      @media screen and (max-width: 450px) {
        max-height: 210px;
      }
  }
}

.loadButton {
  display: block;
  width: 150px;
  margin: 50px auto;
  padding: 7px 0 4px 0;
  background-color: transparent;
  color: #003f47;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #00acc1;
  border-radius: 3px;
  transition: 0.5s;
    &:hover { 
      cursor: pointer;
      letter-spacing: 2px;
    }
    &:active { 
      transform: scale(0.8);
      outline: none; 
    }
    &:focus { outline: none; }
}

.item-enter {
  opacity: 0;
  transform: scale(0.5);
}
.item-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: 500ms ease-in;
}
.item-exit {
  transform: scale(1);
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transform: scale(0.2);
  transition: 500ms ease-in;
}